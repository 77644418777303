import {
    setHeader,
  } from "../../helper";
  
  const actions = {
    mapEpgXmlData: (context, payload) => {
        
        let fetchUrl = `${context.getters.vCmsBaseUrl}`+"subscriber/v1/epg?start=" + payload.start + "&stop=" + payload.stop;
        if(payload.idepgchannel) {
            fetchUrl+="&idepgchannel="+ payload.idepgchannel;
        }
        return new Promise((resolve, reject) => {
            fetch(fetchUrl,
            {
                method: "GET",
                headers: setHeader(),
            }
            ).then((response) => {
                return response.text();
            }).then((data) => {
                resolve({ data });
            }).catch((error) => {console.log("--error--"), reject(error)});
        })
    }
  };

  export default{ actions }